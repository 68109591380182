import { useMutation, useQuery, useQueryClient, UseQueryOptions } from '@tanstack/react-query';

import {
  connectTelegram,
  deleteUserSocial,
  getConnectXUrl,
  getDiscordLink,
  getTwitterLink,
  getUserData,
  TelegramUser,
  verifyDiscord,
  verifyTwitterLink,
  verifyXConnect,
  VerifyXConnectParams,
} from '@api';

import { AUTH_USER_QUERY_KEY } from './keys';
import { userQueryKeys } from './user';

export const useDiscordConnectUrlQuery = (from: string, options?: { enabled?: boolean }) => {
  return useQuery({
    queryKey: [AUTH_USER_QUERY_KEY, 'discordConnectUrl', from],
    queryFn: () => getDiscordLink(from),
    ...options,
  });
};

export const useDiscordConnectMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (options: Parameters<typeof verifyDiscord>[0]['json']) => {
      await verifyDiscord({ json: options });
      return getUserData();
    },
    onSuccess: (data) => {
      queryClient.cancelQueries({ queryKey: userQueryKeys.currentUser });
      queryClient.setQueriesData({ queryKey: userQueryKeys.currentUser }, data);
    },
  });
};

export const useConnectTelegram = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (userData: TelegramUser) => {
      await connectTelegram({ json: { userData } });
      return getUserData();
    },
    onSuccess: (data) => {
      queryClient.cancelQueries({ queryKey: userQueryKeys.currentUser });
      queryClient.setQueriesData({ queryKey: userQueryKeys.currentUser }, data);
    },
  });
};

export const useRemoveUserSocialMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (id: number) => {
      await deleteUserSocial({ id });

      return getUserData();
    },
    onSuccess: (data) => {
      queryClient.cancelQueries({ queryKey: userQueryKeys.currentUser });
      queryClient.setQueriesData({ queryKey: userQueryKeys.currentUser }, data);
    },
  });
};

export const useVerifyTwitterLinkMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: verifyTwitterLink,
    onMutate: () => getUserData(),
    onSuccess: (data) => {
      queryClient.cancelQueries({ queryKey: userQueryKeys.currentUser });
      queryClient.setQueriesData({ queryKey: userQueryKeys.currentUser }, data);
    },
  });
};

export const useCreateTweetUrlQuery = (options?: UseQueryOptions) => {
  return useQuery({
    queryKey: [AUTH_USER_QUERY_KEY, 'twitterUrl'],
    queryFn: () => getTwitterLink(),
    ...options,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    select: (data: { urlWithMessage: string }) => data.urlWithMessage,
  });
};

export const useConnectXUrlQuery = (from: string, options?: { enabled?: boolean }) => {
  return useQuery({
    queryKey: [AUTH_USER_QUERY_KEY, 'connectXUrl', from],
    queryFn: () => getConnectXUrl({ from }),
    ...options,
  });
};

export const useVerifyXConnectMutation = () => {
  return useMutation({
    mutationFn: (params: VerifyXConnectParams) => verifyXConnect({ json: params }),
  });
};
